.root {
  height: 0;
  padding-top: 66.67%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  // margin-bottom: 24px;
    & .three {
      position: absolute;
      display: block;
      border: none;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    & canvas {
      // background: url("./../ocean.svg");
      // background: url('./triangles.svg');
      // background-attachment: #44382a;
      background-image: radial-gradient(farthest-corner at 60% 45%, #646267 0%, #44382a 60%, #1b1611 100%);
      background-repeat: none;
      background-size: cover;
      background-position: center;


    }
  
    & .loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('./../blue-triangle.svg');
      background-repeat: none;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & p.percent {
        color: white;
        font-size: 48px;
        font-weight: 600;
        // transform: translateY(-60px);
        margin: 0;
      }
      & p.subtext {
        color: white;
        font-size: 24px;
        margin-top: 0;
      }  
      & div.loader {
        height: 100px;
        width: 100px;
        background: url('./../loader.svg');
      }
    }
  }
  